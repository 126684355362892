<template>
  <header id="header">
    <div class="container">
      <TheNavigation />
    </div>
  </header>
</template>

<style lang="scss">
#header {
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
  background-color: white;
  box-shadow: inset 0px -7px 0px #00000075;
}
</style>
