import { default as aboutlv6i1FL0CfMeta } from "/opt/build/repo/pages/about.vue?macro=true";
import { default as _91_46_46_46slug_93mvebfMgEVhMeta } from "/opt/build/repo/pages/blog/[...slug].vue?macro=true";
import { default as indexmFsYN4t0XfMeta } from "/opt/build/repo/pages/blog/index.vue?macro=true";
import { default as _91number_93m4FDGc4mXQMeta } from "/opt/build/repo/pages/blog/page/[number].vue?macro=true";
import { default as carsWLsZ4WoWHTMeta } from "/opt/build/repo/pages/cars.vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as toolsJZsomqiLP7Meta } from "/opt/build/repo/pages/tools.vue?macro=true";
export default [
  {
    name: aboutlv6i1FL0CfMeta?.name ?? "about",
    path: aboutlv6i1FL0CfMeta?.path ?? "/about",
    meta: aboutlv6i1FL0CfMeta || {},
    alias: aboutlv6i1FL0CfMeta?.alias || [],
    redirect: aboutlv6i1FL0CfMeta?.redirect,
    component: () => import("/opt/build/repo/pages/about.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93mvebfMgEVhMeta?.name ?? "blog-slug",
    path: _91_46_46_46slug_93mvebfMgEVhMeta?.path ?? "/blog/:slug(.*)*",
    meta: _91_46_46_46slug_93mvebfMgEVhMeta || {},
    alias: _91_46_46_46slug_93mvebfMgEVhMeta?.alias || [],
    redirect: _91_46_46_46slug_93mvebfMgEVhMeta?.redirect,
    component: () => import("/opt/build/repo/pages/blog/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexmFsYN4t0XfMeta?.name ?? "blog",
    path: indexmFsYN4t0XfMeta?.path ?? "/blog",
    meta: indexmFsYN4t0XfMeta || {},
    alias: indexmFsYN4t0XfMeta?.alias || [],
    redirect: indexmFsYN4t0XfMeta?.redirect,
    component: () => import("/opt/build/repo/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: _91number_93m4FDGc4mXQMeta?.name ?? "blog-page-number",
    path: _91number_93m4FDGc4mXQMeta?.path ?? "/blog/page/:number()",
    meta: _91number_93m4FDGc4mXQMeta || {},
    alias: _91number_93m4FDGc4mXQMeta?.alias || [],
    redirect: _91number_93m4FDGc4mXQMeta?.redirect,
    component: () => import("/opt/build/repo/pages/blog/page/[number].vue").then(m => m.default || m)
  },
  {
    name: carsWLsZ4WoWHTMeta?.name ?? "cars",
    path: carsWLsZ4WoWHTMeta?.path ?? "/cars",
    meta: carsWLsZ4WoWHTMeta || {},
    alias: carsWLsZ4WoWHTMeta?.alias || [],
    redirect: carsWLsZ4WoWHTMeta?.redirect,
    component: () => import("/opt/build/repo/pages/cars.vue").then(m => m.default || m)
  },
  {
    name: indexlaFeZuYZkUMeta?.name ?? "index",
    path: indexlaFeZuYZkUMeta?.path ?? "/",
    meta: indexlaFeZuYZkUMeta || {},
    alias: indexlaFeZuYZkUMeta?.alias || [],
    redirect: indexlaFeZuYZkUMeta?.redirect,
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: toolsJZsomqiLP7Meta?.name ?? "tools",
    path: toolsJZsomqiLP7Meta?.path ?? "/tools",
    meta: toolsJZsomqiLP7Meta || {},
    alias: toolsJZsomqiLP7Meta?.alias || [],
    redirect: toolsJZsomqiLP7Meta?.redirect,
    component: () => import("/opt/build/repo/pages/tools.vue").then(m => m.default || m)
  }
]